import React from 'react';
import './ExpandableItem.less';
import ListItem from '../../components/ListItem/ListItem';

type Props = {
  initiallyOpened?: boolean;
  children?: any;
  showSeparator?: boolean;
  title: any;
  value?: any;
  onClickOver: () => void;
  titleClassName: any;
  containerStyles: any;
  showIncomplete?: boolean;
  hideValueOnExpand?: boolean;
  valueStyles?: any;
  brandValueColor?: boolean;
};

const ExpandableItem = ({
  initiallyOpened,
  children,
  value,
  title,
  showSeparator,
  onClickOver,
  titleClassName,
  containerStyles,
  showIncomplete,
  hideValueOnExpand,
  valueStyles,
  brandValueColor,
}: Props) => {
  const [isOpened, setIsOpened] = React.useState(initiallyOpened);

  return (
    <div style={{ width: '100%' }}>
      <ListItem
        containerStyles={containerStyles || { padding: '10px 16px', margin: 0 }}
        leftItem={
          <div className={titleClassName || 'expandable-item-title'}>
            {title}
          </div>
        }
        value={hideValueOnExpand && isOpened ? null : value}
        valueStyles={valueStyles}
        onClick={() => {
          if (children) {
            setIsOpened(!isOpened);
          } else {
            onClickOver();
          }
        }}
        rightIcon={children ? (isOpened ? 'arrowUp' : 'arrowDown') : undefined}
        rightIconSize={12}
        showSeparator={false}
        brandValueColor={children && brandValueColor}
        showIncomplete={showIncomplete && !isOpened}
      />
      {isOpened && children}
      {showSeparator && <div className="expandable-separator" />}
    </div>
  );
};

ExpandableItem.defaultProps = {
  initiallyOpened: false,
  showSeparator: true,
  onClickOver: () => {},
  children: null,
  titleClassName: null,
  containerStyles: null,
  showIncomplete: false,
  value: null,
  valueStyles: null,
  brandValueColor: true,
};

export default ExpandableItem;
