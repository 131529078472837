import React from 'react';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import BrandButton from '../../../components/BrandButton/BrandButton';
import {
  isCTF,
  isLocalizeAdded,
  LocalizePropType,
  TariffData,
} from 'getaway-data-layer';
import { GA_TITLES } from '../../../../utils';
import TariffItem from '../../../components/TariffItem/TariffItem';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './TariffView.less';
import { Checkbox } from 'antd';
import { TARIFF_CONSENT_STATUS } from '../../../../enums/Tariff';
import { TextField } from '@mui/material';
import FileUploadComponent from '../../../components/FileUpload/FileUploadComponent';

type props = {
  localize: LocalizePropType;
  tariffs: TariffData[];
  onClose: () => void;
  tariffConsentStatus: TARIFF_CONSENT_STATUS;
  setTariffConsentStatus: (arg: TARIFF_CONSENT_STATUS) => void;
  onSubmit: () => void;
  tariffSelected: any;
  setTariffSelected: (arg: any) => void;
  upsellingSelected: string;
  setUpsellingSelected: (code: string) => void;
  tlaExtraTariffs: TariffData[];
  onClickExtraOptions: () => void;
  selectedTlaExtraOption: TariffData;
  onResetExtraOptions: () => void;
  openFileSelector: () => void;
  subscriptionNum: string;
  setSubscriptionNum: (arg: any) => void;
  fileLoading: boolean;
  filesContent: any;
  clearFile: () => void;
  isOrg: boolean;
};

const TariffView = ({
  localize,
  tariffs,
  onClose,
  tariffConsentStatus,
  setTariffConsentStatus,
  onSubmit,
  tariffSelected,
  setTariffSelected,
  upsellingSelected,
  setUpsellingSelected,
  tlaExtraTariffs,
  onClickExtraOptions,
  selectedTlaExtraOption,
  onResetExtraOptions,
  openFileSelector,
  fileLoading,
  filesContent,
  subscriptionNum,
  setSubscriptionNum,
  clearFile,
  isOrg,
}: props) => {
  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('tariff.title')}
        description={localize('tariff.header.desc')}
        showHelp
        onClose={onClose}
      />
      <div
        className={
          isLocalizeAdded(localize, 'tariff.faq')
            ? 'screen-body-faq'
            : 'screen-body'
        }
      >
        <div
          className={
            isLocalizeAdded(localize, 'tariff.faq')
              ? 'screen-body-content-faq'
              : 'screen-body-content'
          }
        >
          {selectedTlaExtraOption && (
            <div className="tariff-reset" onClick={onResetExtraOptions}>
              {localize('tariff.tla.extra.reset')}
            </div>
          )}
          {tariffs?.length > 0 &&
            tariffs?.map((tariff) => (
              <TariffItem
                key={tariff.id}
                localize={localize}
                title={localize(`tariff.${tariff.name?.replace(/\s/g, '')}`)}
                price={localize(
                  `tariff.${tariff.name?.replace(/\s/g, '')}.price`
                )}
                description={localize(
                  `tariff.${tariff?.name.replace(/\s/g, '')}.desc`
                )}
                upsellings={tariff?.upsellings}
                upsellingSelected={upsellingSelected}
                setUpsellingSelected={setUpsellingSelected}
                onClick={() => {
                  setTariffSelected(tariff);
                }}
                selected={tariffSelected?.id === tariff?.id}
                showExtraOptionsLink={
                  tlaExtraTariffs?.length > 0 &&
                  tariff.id === '6661aeeb02a17c06e80801c7'
                }
                onClickExtraOptions={onClickExtraOptions}
              />
            ))}
          {selectedTlaExtraOption && (
            <>
              <FileUploadComponent
                localize={localize}
                openFileSelector={openFileSelector}
                filesContent={filesContent}
                loading={fileLoading}
                clearFile={clearFile}
              />
              {selectedTlaExtraOption?.[0]?.id !=
                '6661b99d02a17c06e80801dc' && (
                <TextField
                  required
                  fullWidth
                  id="subscriptionNum"
                  label={localize('tariff.subscription.number')}
                  // helperText={}
                  // error={}
                  sx={{ marginTop: '12px' }}
                  variant="standard"
                  size="small"
                  type="text"
                  value={subscriptionNum}
                  onChange={(e) => setSubscriptionNum(e.target.value)}
                />
              )}
            </>
          )}
        </div>
        {isLocalizeAdded(localize, isOrg ? 'tariff.org.faq' : 'tariff.faq') && (
          <div className="faq">
            <Markdown rehypePlugins={[rehypeRaw]}>
              {localize(isOrg ? 'tariff.org.faq' : 'tariff.faq')}
            </Markdown>
          </div>
        )}
        <div style={{ height: 200 }} />
        <div
          className="tariff-consent"
          style={
            tariffConsentStatus === TARIFF_CONSENT_STATUS.ERROR
              ? { color: 'red' }
              : {}
          }
          onClick={() =>
            setTariffConsentStatus(
              tariffConsentStatus === TARIFF_CONSENT_STATUS.APPROVED
                ? TARIFF_CONSENT_STATUS.OPEN
                : TARIFF_CONSENT_STATUS.APPROVED
            )
          }
        >
          <Checkbox
            style={{ marginRight: 8 }}
            checked={tariffConsentStatus === TARIFF_CONSENT_STATUS.APPROVED}
          />
          <Markdown rehypePlugins={[rehypeRaw]}>
            {localize(isOrg ? 'tariff.org.consent' : 'tariff.consent')}
          </Markdown>
        </div>
        <BrandButton
          id={GA_TITLES.ON_BOARDING.TARIFF_SUBMITTED}
          title={localize('tariff.button.text')}
          onClick={
            [TARIFF_CONSENT_STATUS.OPEN, TARIFF_CONSENT_STATUS.ERROR].includes(
              tariffConsentStatus
            )
              ? () => setTariffConsentStatus(TARIFF_CONSENT_STATUS.ERROR)
              : () => onSubmit()
          }
          enableKeyboardEffect={false}
        />
      </div>
    </div>
  );
};

export default TariffView;
