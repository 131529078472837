import React from 'react';
import './BookingConfirmationView.less';
import NavigationBar from '../../../components/NavigationBar/NavigationBar';
import BrandButton from '../../../components/BrandButton/BrandButton';
import PointItem from '../../../components/PointItem/PointItem';
import ListItem from '../../../components/ListItem/ListItem';
import ExpandableInsuranceItem from '../../../components/ExpandableInsuranceItem/ExpandableInsuranceItem';
import { GA_TITLES } from '../../../../utils';
import {
  CircleData,
  formatMoneyEuro,
  isMBA,
  isPayinPayment,
  maskSepa,
  PaymentMethodDataOutput,
  PreSignupBooking,
  UserDataOutput,
} from 'getaway-data-layer';
import ExpandableEstimatedCost from '../../../components/ExpandableEstimatedCost/ExpandableEstimatedCost';
import ExpandableItem from '../../../components/ExpandableItem/ExpandableItem';
import Hyperlink from 'react-native-hyperlink';
import BrandIcon from '../../../components/BrandIcon/BrandIcon';

type props = {
  localize: any;
  onClose: () => void;
  address: string;
  carPlate: string;
  insurancesData: any;
  selectedInsurance: any;
  onInsuranceSelected: any;
  from: any;
  until: any;
  onSubmit: any;
  onAddressClicked: () => void;
  user: UserDataOutput;
  estimatedCost: any;
  onPressPriceInfo: () => void;
  circles: Array<CircleData>;
  selectedCircle: CircleData;
  onClickCircles: () => void;
  cancellationFee: string;
  mbaCancellationFee: string;
  cancellationDueDate: string;
  alternativeCancelDueDate: string;
  extras: any;
  isDriverInjurySelected: boolean;
  onPressExtraOption: () => void;
  preSignupBooking: PreSignupBooking;
  clearPreSignupBooking: () => void;
  defaultPaymentMethod: PaymentMethodDataOutput;
  onChangePaymentMethod: () => void;
};

function getPaymentIcon(payment) {
  if (payment?.paypal) {
    return 'paypal';
  } else if (payment?.sepa) {
    return 'paymentSepa';
  } else if (payment.card) {
    return payment.card.variant === 'MasterCard'
      ? 'paymentMastercard'
      : 'paymentVisa';
  } else {
    return null;
  }
}

export function getPaymentTitle(payment, localize) {
  if (payment?.paypal) {
    return payment.paypal.name?.length > 27
      ? `${payment.paypal.name.slice(0, 27)}...`
      : payment.paypal.name;
  } else if (payment?.payIn) {
    return localize('booking.payment.payIn.title');
  } else if (payment?.sepa) {
    return maskSepa(payment.sepa.iban);
  } else if (payment?.card) {
    return `****${payment.card.number.substr(-4)}`;
  } else {
    return '';
  }
}

const BookingConfirmationView = ({
  localize,
  onClose,
  carPlate,
  address,
  insurancesData,
  selectedInsurance,
  onInsuranceSelected,
  from,
  until,
  onSubmit,
  onAddressClicked,
  user,
  estimatedCost,
  onPressPriceInfo,
  circles,
  selectedCircle,
  onClickCircles,
  cancellationFee,
  mbaCancellationFee,
  cancellationDueDate,
  alternativeCancelDueDate,
  extras,
  isDriverInjurySelected,
  onPressExtraOption,
  preSignupBooking,
  clearPreSignupBooking,
  defaultPaymentMethod,
  onChangePaymentMethod,
}: props) => {
  const payInDialog = (
    <div className="booking-confirm-warning">
      <div className="booking-confirm-warning-title">
        {localize('booking.confirm.payin.title')}
      </div>
      <div className="booking-confirm-warning-msg">
        {localize('booking.confirm.payin.text')}
      </div>
    </div>
  );

  const scrollToPaymentSection = () => {
    const objDiv = document.getElementById('screen-body');
    if (objDiv) objDiv.scrollTop = objDiv?.scrollHeight;
  };

  const bottomSheet = () => (
    <div className="booking-confirmation-bottom-sheet">
      <ExpandableItem
        containerStyles={{ width: '100%', paddingBottom: 12, paddingTop: 0 }}
        title={
          defaultPaymentMethod ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!!getPaymentIcon(defaultPaymentMethod) && (
                <BrandIcon
                  id={getPaymentIcon(defaultPaymentMethod)}
                  name={getPaymentIcon(defaultPaymentMethod)}
                  style={{ width: 27, height: 27, marginRight: 8 }}
                />
              )}
              {defaultPaymentMethod
                ? getPaymentTitle(defaultPaymentMethod, localize)
                : ''}
            </div>
          ) : (
            <img
              src="/images/payment-methods.png"
              alt="payment-methods"
              style={{ height: 15 }}
            />
          )
        }
        titleClassName="booking-confirmation-payment-title"
        value={formatMoneyEuro(estimatedCost?.totalCost)}
        valueStyles={{ fontWeight: 600, color: 'black' }}
        onClickOver={scrollToPaymentSection}
        showSeparator={false}
        brandValueColor={false}
      />
      <BrandButton
        id={GA_TITLES.BOOKING.CONFIRM}
        title={localize(
          user
            ? 'booking.confirmation.button'
            : 'booking.guest.confirmation.button'
        )}
        onClick={onSubmit}
        containerStyles={{
          position: 'relative',
          left: 'unset',
          right: 'unset',
          bottom: 'unset',
          width: '100%',
        }}
      />

      <div className="booking-confirmation-cancellation">
        {cancellationFee || (isMBA() && mbaCancellationFee)
          ? localize('booking.cancellation.text', cancellationDueDate)
          : localize('booking.free.cancellation.text')}
      </div>
    </div>
  );

  return (
    <div className="screen-container">
      <NavigationBar
        title={localize('booking.confirmation.title')}
        description=""
        onClose={onClose}
      />
      <div
        id="screen-body"
        className="screen-body booking-confirmation-content"
      >
        <PointItem title={localize('station.booking.group')} />
        <div className="booking-confirm-section">
          <img
            alt="dashedPath"
            src="/images/dashedPath.png"
            className="booking-confirmation-dashed"
          />
          {circles?.length > 1 && selectedCircle?.name && (
            <ExpandableItem
              containerStyles={{}}
              title={localize('booking.consent.circle')}
              titleClassName="circles-item-title"
              value={
                selectedCircle?.name.length > 17
                  ? `${selectedCircle?.name.slice(0, 17)}...`
                  : selectedCircle?.name
              }
              onClickOver={onClickCircles}
            />
          )}
          <ExpandableInsuranceItem
            localize={localize}
            insurancesData={insurancesData}
            onInsuranceSelected={onInsuranceSelected}
            selectedInsurance={selectedInsurance}
          />
          {extras?.map((extra: { code: string }) => (
            <ListItem
              key={extra.code}
              title={localize(`rental.extra.${extra.code}.title`)}
              greyTextColor
              onClick={onPressExtraOption}
              checkboxChecked={isDriverInjurySelected}
              checkboxItem
            />
          ))}
          <ListItem
            title={localize('rental.fuelSettings.where')}
            greyTextColor
            value={address}
            brandValueColor
            rightIcon="navigationArrow"
            rightIconSize={14}
            showSeparator
            onClick={onAddressClicked}
          />
          <div className="booking-confirmation-insurance-desc">
            {localize('bookings.where.desc')}
          </div>
        </div>
        <PointItem title={localize('booking.headline.reservation')} />
        <div className="booking-confirm-section" style={{ paddingBottom: 16 }}>
          <div className="booking-confirm-section-line" />
          <ListItem
            title={localize('booking.confirm.profile')}
            greyTextColor
            carPlate={carPlate}
            showSeparator
            onClick={() => {}}
          />
          {!!from && (
            <ListItem
              title={localize('booking.confirm.from')}
              greyTextColor
              value={from}
              valueStyles={{ color: 'black' }}
              showSeparator
              onClick={() => {}}
            />
          )}
          {!!until && (
            <ListItem
              title={localize('booking.confirm.to')}
              greyTextColor
              value={until}
              valueStyles={{ color: 'black' }}
              showSeparator
              onClick={() => {}}
            />
          )}
        </div>
        <PointItem title={localize('booking.headline.payment')} />
        <div className="booking-confirm-section">
          <div className="booking-confirm-section-line" />
          {isPayinPayment(user?.paymentMethods) && payInDialog}
          {defaultPaymentMethod && (
            <ListItem
              title={localize('booking.payment')}
              greyTextColor
              value={`${getPaymentTitle(defaultPaymentMethod, localize)} | ${
                defaultPaymentMethod?.billingInfo?.billingProfile
              }`}
              brandValueColor
              showSeparator
              rightIcon={
                user?.paymentMethods?.filter(
                  (payment) => payment.status === 'verified'
                )?.length > 1
                  ? 'arrowDown'
                  : undefined
              }
              rightIconSize={12}
              onClick={
                user?.paymentMethods?.filter(
                  (payment) => payment.status === 'verified'
                )?.length > 1
                  ? onChangePaymentMethod
                  : undefined
              }
            />
          )}
          <ExpandableEstimatedCost
            localize={localize}
            insurancesData={insurancesData}
            estimatedCost={estimatedCost}
            onPressPriceInfo={onPressPriceInfo}
          />
          <ExpandableItem
            title={localize('booking.fee.title')}
            containerStyles={{}}
            titleClassName="circles-item-title"
            value={cancellationDueDate}
            showSeparator
          >
            {(cancellationFee || (isMBA() && mbaCancellationFee)) && (
              <div style={{ paddingBottom: 16 }}>
                <Hyperlink
                  linkStyle={{ fontWeight: 700 }}
                  linkText={(url) => {
                    if (url === localize('booking.fee.fake.url')) {
                      return localize(
                        isMBA() && mbaCancellationFee
                          ? mbaCancellationFee
                          : cancellationFee
                      );
                    }

                    if (url === localize('booking.date.fake.url')) {
                      return alternativeCancelDueDate;
                    }

                    return url;
                  }}
                  onPress={() => {}}
                >
                  {localize(
                    'booking.cancel.fee.footnote',
                    localize('booking.fee.fake.url'),
                    localize('booking.date.fake.url')
                  )}
                </Hyperlink>
              </div>
            )}
          </ExpandableItem>

          {estimatedCost?.defaultBlockPaymentAmount !== undefined && (
            <ExpandableItem
              title={localize('booking.deposit.title')}
              containerStyles={{}}
              titleClassName="circles-item-title"
              value={formatMoneyEuro(estimatedCost?.defaultBlockPaymentAmount)}
              showSeparator
            >
              <div style={{ paddingBottom: 16 }}>
                <Hyperlink
                  linkStyle={{ fontWeight: 700 }}
                  linkText={(url) => {
                    if (url === localize('booking.fee.fake.url')) {
                      return localize(
                        formatMoneyEuro(
                          estimatedCost?.defaultBlockPaymentAmount
                        )
                      );
                    }

                    if (url === localize('booking.date.fake.url')) {
                      return alternativeCancelDueDate;
                    }

                    return url;
                  }}
                  onPress={() => {}}
                >
                  {localize(
                    'booking.deposit.footnote',
                    localize('booking.fee.fake.url'),
                    localize('booking.date.fake.url')
                  )}
                </Hyperlink>
              </div>
            </ExpandableItem>
          )}
        </div>
        {preSignupBooking && (
          <div className="booking-cancel" onClick={clearPreSignupBooking}>
            {localize('booking.pre.signup.clear')}
          </div>
        )}
        <div className="booking-confirmation-vertical-space" />
        {bottomSheet()}
      </div>
    </div>
  );
};

export default BookingConfirmationView;
